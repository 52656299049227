body {
  margin: 0;
  padding: 0;
  background-color: #012e2d;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menuholder {
  position: absolute;
  top: 0;
  right: -210px;
  z-index: 5;
  background-color: #fff;
  padding: 80px 36px 0;
  min-height: 100%;
  transition: right 0.3s ease-in-out;
}

* {
  font-family: Glasgow;
  font-weight: 300;
  color: #fff;
}

h1,
h2,
h3,
h4,
h4 > strong {
  font-family: Glasgow;
  font-weight: 700;
  letter-spacing: 0.03em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h5 {
  font-size: 17px;
  line-height: 20px;
  font-family: Glasgow;
  font-weight: 500;
  margin-bottom: 8px;
}

a {
  color: #f7ac00;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul > li {
  margin: 0;
  padding: 0;
}
